import * as Cookie from 'cookie';
import ReactGA from 'react-ga4';

import { CookieService } from './CookieService';
import { QuantcastService } from "./QuantcastService";
import { LocalStorageService } from "./LocalStorage";
import { SessionStorageService } from './SessionStorage';
import { MiscUtils } from "../../utils/MiscUtils";
import { environment } from '../config/environment';
import { LOCALSTORAGE_ITEMS, LS_COOKIE_CONSTS } from '../models/Enums';

export class StartupService {
    public static init() {
        this.securityUrlFix();
        this.initThirdPartyScripts();
        this.setArkPromo();
        this.setFirstDayOfVisit();
        this.setUserAgent();
        this.setGameSequence();
        this.setReturningStatus();
        this.setArkFirstVisit();
    }

    private static securityUrlFix() {
        if (window.location.href.includes('__proto__')) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }

    private static initThirdPartyScripts() {
        if (environment.TAGMANAGER_SITE_ID != '') {
            this.loadScript(
                null,
                `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${environment.TAGMANAGER_SITE_ID}');`
            );
        }

        this.loadScript(
            null,
            `
                    !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject = a, t.AF = t.AF || function () {
                        (t.AF.q = t.AF.q || []).push([Date.now()].concat(Array.prototype.slice.call(arguments)))
                    },
                        t.AF.id = t.AF.id || i, t.AF.plugins = {}, o = e.createElement(n), p = e.getElementsByTagName(n)[0], o.async = 1,
                        o.src = "https://websdk.appsflyer.com?" + (c.length > 0 ? "st=" + c.split(",").sort().join(",") + "&" : "") + (i.length > 0 ? "af_id=" + i : ""),
                        p.parentNode.insertBefore(o, p)}(window,document,"script",0,"AF","banners",{banners: {key: "b1bebca0-1adc-44e5-905d-615eeed2381d"}});
                `
        );

        if (environment.HOTJAR_SITE_ID > 0) {
            // hotjar script load
            this.loadScript(
                null,
                ` (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${environment.HOTJAR_SITE_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
            );
        }

        if (!QuantcastService.blockedByGdprConsent()) {
            this.loadScript(null, ` (function(w,d,t,r,u) { var f,n,i; w[u]=w[u]||[],f=function() { 
          var o={ti:"343148317", enableAutoSpaTracking: true}; o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad") }, 
          n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function() { 
          var s=this.readyState; s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null) }, 
          i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i) }) (window,document,"script","//bat.bing.com/bat.js","uetq"); `
            );
        }
    }

    public static setArkPromo() {
        const arkpromoValue = this.getParameterByName('arkpromo', window.location.href);

        if (arkpromoValue !== null) {
            ReactGA.set({ dimension11: arkpromoValue });
        }

        if (arkpromoValue === 'mgid' || arkpromoValue === 'adwords') {
            ReactGA.set({ dimension17: arkpromoValue });
        }
    }

    public static setFirstDayOfVisit() {
        ReactGA.set({ dimension12: this.getUserDate() });
    }

    public static setUserAgent() {
        ReactGA.set({ dimension14: navigator.userAgent });
    }

    public static setGameSequence() {
        const gameSequence = SessionStorageService.getItem('GameSequence');

        if (gameSequence) {
            ReactGA.set({ dimension13: gameSequence });
        }
    }

    public static getParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }

        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);

        if (!results) {
            return null;
        }

        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    public static setReturningStatus() {
        const gaCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.GA);
        const d = new Date();

        d.setTime(d.getTime() + 1825 * 24 * 60 * 60 * 1000);

        if (typeof gaCookie !== 'boolean' && gaCookie.length > 0) {
            const cookieValues = gaCookie.split('.');
            const currentDate = new Date();
            const sec = (currentDate.getTime() - parseInt(cookieValues[3], 10) * 1000) / 1000;

            if (sec < 60 * 60) {
                CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_VISITOR_MAIN, 'new');
            } else {
                CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_VISITOR_MAIN, 'returning');
            }
        } else {
            CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_VISITOR_MAIN, 'new');
        }
    }

    //
    // NPS VWO javascript variable set - Deprecated comment
    //
    public static setArkFirstVisit() {
        const gaCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.GA);
        let arkUserFirstVisit2: number;

        if (gaCookie !== false && typeof gaCookie !== 'boolean') {
            const gaCookie2 = gaCookie.split('.');
            const currentDate = new Date();

            arkUserFirstVisit2 = Math.round(
                (currentDate.getTime() - parseInt(gaCookie2[3], 10) * 1000) / (3600 * 24 * 1000)
            );
        } else {
            arkUserFirstVisit2 = 0;
        }

        (window as any).arkUserFirstVisit2 = arkUserFirstVisit2;
        CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_USER_FIRST_VISIT_2, arkUserFirstVisit2);

        const firstLoadCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_USER_FIRST_LOAD);
        let arkUserFirstLoad: boolean;

        if (firstLoadCookie !== false && typeof firstLoadCookie !== 'boolean') {
            arkUserFirstLoad = false;
        } else {
            arkUserFirstLoad = true;
        }

        CookieService.setArkCookie(LS_COOKIE_CONSTS.ARK_USER_FIRST_LOAD, arkUserFirstLoad);

        const firstVisitDate = LocalStorageService.getItem(LOCALSTORAGE_ITEMS.ARK_USER_FIRST_VISIT);
        const isNewUser = (CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_VISITOR_MAIN) as string) === 'new';

        if (!MiscUtils.isServer && !firstVisitDate && isNewUser) {
            LocalStorageService.setItem(LOCALSTORAGE_ITEMS.ARK_USER_FIRST_VISIT, (new Date()).toISOString());
        }
    }

    public static isFirstHourStatus() {
        const gaCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.GA);

        if (typeof gaCookie !== 'boolean' && gaCookie.length > 0) {
            const cookieValues = gaCookie.split('.');
            const currentDate = new Date();
            const sec = (currentDate.getTime() - parseInt(cookieValues[3], 10) * 1000) / 1000;

            return sec < 60 * 60;
        }

        return true;
    }

    //
    // Return date for first visit in format: 31_03_2019 based on google cookie
    //
    private static getUserDate = function () {
        const cookies = Cookie.parse(window.document.cookie);
        const gaCookie = cookies._ga;
        let dateVar: any;

        dateVar = new Date();

        if (typeof gaCookie !== 'undefined' && gaCookie.length > 0) {
            const timestamp = parseInt(cookies._ga.split('.')[3], 10);

            if (timestamp > 0) {
                dateVar = new Date(timestamp * 1000);
            }
        }

        const year = dateVar.getFullYear();
        const month = dateVar.getMonth() + 1;
        const date = dateVar.getDate();

        return `${date}_${month}_${year}`;
    };

    private static loadScript(src?: string, code?: string) {
        const script = document.createElement('script');

        script.defer = true;
        if (src) script.src = src;
        if (code) script.text = code;
        document.body.appendChild(script);
    }
}
